<template>
  <b-card>
    <div class="mb-2">Set an announcement banner to be shown for all users at login. An empty message will not be shown.</div>

    <b-form-group
      label="Announcement Message"
      label-for="banner-message"
    >
      <b-form-input
        id="banner-message"
        v-model="message"
        data-cy="bannerMessage"
      />
    </b-form-group>

    <b-form-group
      label="Expiration"
      label-for="banner-expiration"
    >
      <b-form-select
        id="banner-expiration"
        v-model="isEternal"
        class="mb-50"
      >
        <b-form-select-option value="true">
          Never
        </b-form-select-option>
        <b-form-select-option value="">
          Set date and time
        </b-form-select-option>
      </b-form-select>

      <b-form v-if="!isEternal" inline>
        <b-form-datepicker v-model="expiryDate" class="mr-50" />
        <b-form-timepicker v-model="expiryTime" />
      </b-form>
    </b-form-group>

    <b-form inline class="flex-row-reverse">
      <b-button variant="primary" data-cy="bannerSubmit" @click="saveBanner">
        Save
      </b-button>
      <b-button class="mr-50" variant="secondary" data-cy="bannerClear" @click="clearBanner">
        Clear Banner
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
export default {
  name: 'AdminBanner',
  data() {
    return {
      message: '',
      expiryDate: '',
      expiryTime: '',
      isEternal: true,
      untilDate: 'Never',
    }
  },
  async mounted() {
    await this.loadBanner()
  },
  methods: {
    async loadBanner() {
      const banner = await this.$coreService.adminCliApi.getBanner()
      if (banner) {
        this.message = banner.message
        this.$store.commit('app/SET_BANNER_MESSAGE', this.message)
        if (banner.expiry !== '') {
          this.expiryDate = banner.expiry.split(' ').filter((__, c) => c < 4).join(' ');
          [this.expiryTime] = new Date(banner.expiry).toTimeString().split(' ')
          this.isEternal = ''
        } else {
          this.isEternal = 'true'
        }
      }
    },
    async saveBanner() {
      let expiry = ''
      if (this.expiryDate && this.expiryTime !== 'Invalid' && !this.isEternal) {
        expiry = `${this.expiryDate.split(' ')[0]} ${this.expiryTime}`
      }
      await this.$coreService.adminCliApi.updateBanner(this.message, expiry)
      await this.loadBanner()
    },
    async clearBanner() {
      this.isEternal = true
      this.message = ''
      await this.$coreService.adminCliApi.deleteBanner(this.message, '')
    },
  },
}
</script>
